<template>
  <van-popup
    v-model:show="popupShow"
    position="right"
    :style="{ height: '100%', width: '100%', background: '#f6f7fa' }"
  >
    <div class="reject-popup-body">
      <van-nav-bar title="驳回延期开店申请" left-text="返回" left-arrow @click-left="popupShow = false" />
      <div class="rosa">
        <div class="content">
          <div class="title">驳回检查内容</div>
          <van-checkbox-group v-model="checked">
            <van-checkbox :name="item.id" v-for="item in option" :key="item.id">
              {{ item.title }}
            </van-checkbox>
          </van-checkbox-group>
        </div>
        <div class="reason">
          <div class="title">驳回原因</div>
          <van-cell-group inset style="border-radius: 4px;">
            <van-field
              v-model="reason"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
          </van-cell-group>
        </div>
        <div class="btn">
          <van-button type="primary" block @click="submit">提交</van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { ref } from "vue";
import { useVModel } from "@vueuse/core";
import { Toast } from "vant";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    option: {
      type: Object,
      required: true
    }
  },
  emits: ["update:show", "submit"],
  setup(props, { emit }) {
    const popupShow = useVModel(props, "show", emit);

    const reason = ref("");
    const checked = ref([]);
    const submit = () => {
      if (checked.value.length === 0) {
        Toast.fail("请选择检查内容");
        return;
      }
      if (!reason.value) {
        Toast.fail("请填写拒绝原因");
        return;
      }
      emit("submit", {
        reason: reason.value,
        checked: checked.value.toString()
      });
    };

    return { checked, reason, popupShow, submit };
  }
};
</script>

<style lang="less" scoped>
.reject-popup-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rosa {
  background-color: rgb(246, 247, 250);
  flex: 1;
  min-height: 0;
  .title {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2c3746;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .content {
    background-color: #fff;
    padding: 15px 12px;
    /deep/ .van-checkbox-group {
      font-size: 15px;
    }
    /deep/ .van-checkbox {
      margin-bottom: 10px;
    }
    /deep/ .van-checkbox__label {
      font-family: PingFangSC-Regular;
      color: #6a717b;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
  .reason {
    margin-block: 8px;
    background-color: #fff;
    padding: 15px 12px;
    /deep/ .van-cell {
      border: 1px solid #babec4;
      border-radius: 4px;
    }
  }
  .btn {
    height: 58px;
    background: #ffffff;
    width: 94%;
    position: fixed;
    bottom: 0px;
    padding: 12px 10px;
  }
}
</style>
