
import { nextTick, reactive, ref } from "vue";
import { storesManageService } from "@/service";
import { useStore } from "@/store";
import { Dialog } from "vant";
import RejectDelayApply from "./components/RejectDelayApply.vue";
import RejectOpenShopApply from "./components/RejectOpenShopApply.vue";

export default {
  components: {
    RejectDelayApply,
    RejectOpenShopApply
  },
  setup() {
    const active = ref(0);
    const store = useStore();
    const storeInfo = reactive<any>({
      ocList: [],
      async getOcList() {
        try {
          const { data } = await storesManageService.storesOcLists({
            page: 1,
            pagesize: 9999999,
            approval_uid: store.state.userInfo?.id
          });
          storeInfo.ocList = data.data;
        } catch (error) {}
      },
      delayLists: [],
      async getDelayLists() {
        try {
          const { data } = await storesManageService.storesDelayLists({
            page: 1,
            pagesize: 9999999,
            approval_uid: store.state.userInfo?.id
          });
          storeInfo.delayLists = data.data;
        } catch (error) {}
      }
    });

    storeInfo.getOcList();
    storeInfo.getDelayLists();

    const rejectDelayApplyInfo = reactive<any>({
      show: false,
      onSubmit() {}
    });

    const rejectOpenShopApplyInfo = reactive<any>({
      show: false,
      option: [],
      onSubmit() {}
    });

    const optClick = async (data: any, type: string) => {
      try {
        switch (type) {
          case "2":
            Dialog.confirm({
              title: "",
              message: `确认通过"${data.stores_name}"的${data.oc_type_name}吗`
            }).then(async () => {
              storesManageService.storesOCApproval({
                oc_id: data.id,
                approval_type: "1"
              });
              storeInfo.ocList = [];
              await nextTick();
              storeInfo.getOcList();
            });
            break;

          case "3": {
            rejectOpenShopApplyInfo.show = true;
            rejectOpenShopApplyInfo.option = data.oc_json_arr.map((item: any) => {
              return {
                title: item.title,
                id: item.oc_json_id
              };
            });
            const res: any = await new Promise(resolve => {
              rejectOpenShopApplyInfo.onSubmit = (res: any) => {
                resolve(res);
              };
            });
            rejectOpenShopApplyInfo.show = false;
            storesManageService.storesOCApproval({
              oc_id: data.id,
              approval_type: "2",
              oc_json_ids: res.checked,
              reason: res.reason
            });
            storeInfo.ocList = [];
            await nextTick();
            storeInfo.getOcList();
            break;
          }
          case "5": {
            Dialog.confirm({
              title: "",
              message: `确认通过"${data.stores_name}"的${data.oc_type_name}吗`
            }).then(async () => {
              storesManageService.storesDelayApproval({
                oc_id: data.id,
                approval_type: "1"
              });
              storeInfo.delayLists = [];
              await nextTick();
              storeInfo.getDelayLists();
            });
            break;
          }
          case "6": {
            rejectDelayApplyInfo.show = true;
            const reason = await new Promise(resolve => {
              rejectDelayApplyInfo.onSubmit = (reason: string) => {
                resolve(reason);
              };
            });
            rejectDelayApplyInfo.show = false;
            storesManageService.storesDelayApproval({
              oc_id: data.id,
              approval_type: "2",
              reason
            });
            storeInfo.delayLists = [];
            await nextTick();

            storeInfo.getDelayLists();
            break;
          }

          default:
            break;
        }
      } catch (error) {
        console.log(error, "====");
      }
    };

    return { active, storeInfo, rejectDelayApplyInfo, rejectOpenShopApplyInfo, optClick };
  }
};
